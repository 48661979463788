import React from "react"
import { graphql, navigateTo } from "gatsby"
import { Flex, Text, Button } from "rebass"
import { Layout, SEO } from "../components/"
import { CldImage } from "@extend"

const Collaborate = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="Collaborate" />
      <Text as="h1" mt={'60px'} mb={10} textAlign='center'>Collaborate</Text>
      <Flex flexDirection='column' alignItems='center'>
        <CldImage alt="Collaborate" width={.4} mt={20} src='https://res.cloudinary.com/yabuko/image/upload/v1588348716/website/20200501_172521_izxtj7.jpg' transformations={['e_blackwhite', 'c_scale', 'w_600']}/>
        <Text as="h2" my={40}>Interested in working with us?</Text>
        <Button
          mb={'30px'}
          onClick={() => navigateTo('/contact')}
          className="subscribe-navbar"
          letterSpacing={3}
          variant='primary' 
          px={20}
          py={9}
          fontWeight={700}
          width={'auto'}
          >
          Contact us
        </Button>
      </Flex>      
    </Layout>
  )
}

export default Collaborate
export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    pictures: allFile(filter: {name: {regex: "/cake/"}}) {
      edges {
        node {
          id
          publicURL
        }
      }
    }
  }
`